import React, { useState, useEffect } from 'react';
import './InstallPrompt.scss';
import shareIcon from '../../assets/img/share_icon.png';

const InstallPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    // Check if device is iOS
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);

    // Handle standard PWA install prompt
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowPrompt(true);
    });

    // Show iOS prompt if using Safari
    if (isIOSDevice && !window.navigator.standalone) {
      setShowPrompt(true);
    }
  }, []);

  const handleInstallClick = () => {
    if (isIOS) return; // No action needed for iOS
    
    if (!deferredPrompt) return;
    
    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      }
      setDeferredPrompt(null);
      setShowPrompt(false);
    });
  };

  if (!showPrompt) return null;

  return (
    <div className="install-prompt">
      <div className="install-prompt-content">
        {isIOS ? (
          <>
            <p>Per installare l'app:</p>
            <ol>
              <li>Tocca l'icona di condivisione <img src={shareIcon} alt="Share" className="ios-share-icon" /></li>
              <li>Aggiungi alla schermata Home</li>
            </ol>
          </>
        ) : (
          <>
            <p>Installa l'app per un'esperienza migliore!</p>
            <button onClick={handleInstallClick} className="install-button">
              Installa
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default InstallPrompt; 